import { Menu, Radio, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ContentNavbar from '../../../../../Components/contentNavbar';
import NoData from '../../../../../Components/NoData/NoData';
import { getMyLeadActivityList, reassignTaskService, updateLeadState } from '../../../../../Service/MyLeadsService';
import SimpleBarReact from 'simplebar-react';
import GenericTaskDetailsModal from './GenericTaskDetailsModal/GenericTaskDetailsModal';
import Pagination from 'rc-pagination';
import { pagination } from '../../../../../Utilities/Pagination/paginationUtilites';
import locale from 'rc-pagination/es/locale/en_US';
import moment from 'moment';
import RescheduleHeader from '../Reschedule/RescheduleHeader/RescheduleHeader';
import Reschedule from '../Reschedule/Reschedule';



const GenericTaskDetails = ({ leadId, activeTab }) => {
    console.log("task lead id", leadId);
    const [taskModal, setTaskModal] = useState(false);
    const [filter, setFilter] = useState("");
    const [taskList, setTaskList] = useState([]);
    const [selectedTask, setSelectedTask] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [numberPerPage, setNumberPerPage] = useState(8);
    const [count, setCount] = useState(0);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [modalType, setModalType] = useState("details");
    const [selectedState, setSelectedState] = useState(null);
    const [rescheduleDetails, setRescheduleDetails] = useState(
        {
            previousAppointmentId: "",
            leadId: "",
            leadActivityType: ["Add Task"],
            title: "",
            description: "",
            taskAttendees: [],
            taskStartDate: null,
            taskEndDate: null,
            salesExecutiveId: ""

        }
    )
    const [newAppointmentId, setNewAppointmentId] = useState("");
    const options = [
        {
            label: "All",
            value: ""
        },

        {
            label: "Cold", value: 4

        },
        {
            label: "Hot", value: 5

        },
        {
            label: "Future", value: 6

        },
        {
            label: "Discarded", value: 3

        },
        {
            label: "Lost", value: 7

        }
    ]
    const items = [
        {
            label: <Radio.Group value={filter} options={options} className="items-wrapper" />,
            key: "1"
        }


    ]
    const menu = (
        <Menu items={items} />
    );

    const handleReset = () => {
        setRescheduleDetails(
            {
                previousAppointmentId: "",
                leadId: "",
                leadActivityType: ["Add Task"],
                title: "",
                description: "",
                taskAttendees: [],
                taskStartDate: null,
                taskEndDate: null,
                salesExecutiveId: ""

            }
        )
        setModalType("details");
        setNewAppointmentId("");
    }

    const closeModal = () => {
        setTaskModal(false);
        handleReset();
    }
    const handleCancel = () => {
        getMyLeadActivityService(selectionRange[0], null, 0);
        closeModal();
    }
    const handleModal = (taskDetails) => {
        console.log(taskDetails?.activityState === "Reassigned")
        if((taskDetails?.activityState !== "Reassigned")){
        setSelectedTask(taskDetails);
        setTaskModal(true);
        }

    }
    console.log("count", count);
    const updateState = async (type) => {
        console.log("update lead state");
        try {

            const response = await updateLeadState({ taskId: selectedTask?.activityId, taskState: selectedState });
            if (response.status === "success") {
                toast.success("successfully updated state");
                if (type === "detailsSaveandClose") {
                    handleCancel();
                }
            }
            else {
                toast.error("Failed to update state");
            }

        } catch (error) {
            console.log("error ", error);
            toast.error("Failed to update state");
        }
    }

    const getMyLeadActivityService = async (dateRange, activity, pageNo) => {

        try {
            setShowDateFilter(false);
            let body;
            if (pageNo) {
                setCurrentPage(1);
                body = {
                    leadId: leadId,
                    activityType: "Add Task",
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format('YYYY-MM-DD') : null,
                    dateTo: dateRange.endDate ? moment(dateRange?.endDate).format('YYYY-MM-DD') : null,
                    pageIndex: pageNo,
                    numberPerPage: numberPerPage

                }
            }
            else {
                body = {
                    leadId: leadId,
                    activityType: "Add Task",
                    dateFrom: dateRange?.startDate ? moment(dateRange?.startDate).format('YYYY-MM-DD') : null,
                    dateTo: dateRange?.endDate ? moment(dateRange?.endDate).format('YYYY-MM-DD') : null,
                    pageIndex: currentPage,
                    numberPerPage: numberPerPage

                }

            }
            console.log("body", body, selectionRange);
            const response = await getMyLeadActivityList(body);
            console.log("response", response);
            if (response.status === "success") {
                setTaskList(response.data);
                setCount(response.count);

            }
            else {
                setTaskList([]);
                setCount(0);
                // toast.error('Something went wrong');
            }

        } catch (error) {
            setTaskList([]);
            setCount(0);
            toast.error('Something went wrong');

        }
    }

    const fieldvalidation=()=>{
        if (rescheduleDetails?.title && rescheduleDetails?.description && rescheduleDetails?.taskAttendees.length && rescheduleDetails?.taskStartDate && rescheduleDetails?.taskEndDate) {
         return true;
        }
        else{
            return false;
        }
    }

    // function for pagination:
    const handleCurrentPage = (pageindex, perpage) => {
        console.log(pageindex, perpage);
        setCurrentPage(pageindex);
    }

    const reassignTask = async (type) => {
        try {
            console.log("type", type);
            let body;
            if (newAppointmentId) {
                body = {
                    ...rescheduleDetails,
                    newAppointmentId: newAppointmentId
                }
            }
            else {
                body = {
                    ...rescheduleDetails
                }

            }
            console.log("reassign", body);
            const response = await reassignTaskService(body);
            if (response.status === "success") {
                if (type === "reschedulesaveandclose") {
                    handleCancel();
                    // toast.success('Successfully Rescheduled Task');
                }
                else{
                setNewAppointmentId(response?.newAppointmentId);
                }
                toast.success('Successfully Reassign Task');
            }
            else {
                toast.error('Failed to Reassign Task');
            }

        } catch (error) {
            toast.error("Failed to Reassign Task");

        }
    }

    const handleSave = async (type) => {
        if (type === "detailsSave") {
            updateState(type);
        }
        else if (type === "detailsSaveandClose") {
            updateState(type);
        }
        else if (type === "rescheduleSave" && fieldvalidation()) {
            reassignTask(type);
        }
        else if (type === "reschedulesaveandclose" && fieldvalidation()) {
            reassignTask(type);
        }
        else{
            toast.error("Please Fill All details");
        }
    }

    useEffect(() => {
        getMyLeadActivityService(selectionRange[0], null, 0);
    }, [currentPage, activeTab])


    return (
        <div className="whContentBlock">
            <div className='contentBody'>
                <ContentNavbar
                    leadsData={null}
                    setSearchInput={null}
                    menu={menu}
                    setShowDateFilter={setShowDateFilter}
                    showDateFilter={showDateFilter}
                    onApplyFilter={getMyLeadActivityService}
                    selectionRange={selectionRange}
                    setSelectionRange={setSelectionRange}
                    count={count}
                    listType="Tasks"
                />
                <div className='whAppTable'>
                    <div className="tableList">
                        <div className="tableHead">
                            <div className="tableRow">
                                <div className="listItem sNo text-center">S.No</div>
                                <div className="listItem userName">NAME</div>
                                <div className="listItem mobile">MOBILE</div>
                                <div className="listItem comments">SALES EXECUTIVE COMMENTS</div>
                                <div className="listItem tastTitle">TASK TITLE</div>
                                <div className="listItem msg">TASK DESCRIPTION</div>
                                <div className="listItem startDate">START DATE</div>
                                <div className="listItem dueDate">DUE DATE</div>
                                <div className="listItem status">STATUS</div>
                            </div>
                        </div>
                        {(taskList.length === 0 || taskList === undefined) ?
                            <NoData />
                            :
                            <>
                                <SimpleBarReact style={{ maxHeight: 640 }}>
                                    {taskList?.map((item, index) => {
                                        return (
                                            <div className="tableRow" onClick={() => handleModal(item)}>
                                                <div className="listItem sNo text-center" data-head="S.No">
                                                    <span>
                                                        {/* {index + 1} */}
                                                        {

                                                            ((currentPage - 1) * numberPerPage) + index + 1
                                                        }
                                                    </span>
                                                </div>
                                                <div className="listItem userName" data-head="NAME">
                                                    <div class="nameEmailItem">
                                                        <span>{item.leadName}</span>
                                                        <span className="userEmailId">
                                                            {item.leadEmail}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="listItem mobile" data-head="MOBILE">
                                                    {item.leadMobile}
                                                </div>
                                                {/* <div className="listItem comments" data-head="MOBILE">
                                                    {item.remarks ? item.remarks : "Nil"}
                                                </div> */}
                                                <div className="listItem comments" data-head=" SALES EXECUTIVE COMMENTS">
                                                    <span>{item.remarks ? item.remarks.replace(/(<([^>]+)>|&nbsp;)/ig, '\n') : "Nil"}</span>
                                                </div>

                                                <div className="listItem tastTitle" data-head="TASK TITLE">
                                                    {item.activityName}
                                                </div>
                                                {/* <div className="listItem msg" data-head="TASK DESCRIPTION">
                                                    {item?.activityDescription}
                                                </div> */}
                                                <div className="listItem msg" data-head="TASK DESCRIPTION">
                                                    {item?.activityDescription ? item?.activityDescription.replace(/(<([^>]+)>|&nbsp;)/ig, '\n') : ""}
                                                </div>
                                                <div className="listItem startDate" data-head="START DATE">
                                                    {
                                                        moment(item?.activityStartDate).format("D MMM, YYYY ")
                                                    }
                                                </div>
                                                <div className="listItem dueDate" data-head="DUE DATE">
                                                    {
                                                        moment(item?.activityDueDate).format("D MMM, YYYY ")
                                                    }
                                                </div>
                                                <div className="listItem status" data-head="STATUS">
                                                    <button className={`btn rounded-pill Active`}
                                                    >
                                                        {
                                                            item?.activityState
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </SimpleBarReact>
                            </>
                        }
                    </div>
                </div>
                <Pagination
                    current={currentPage}
                    pageSize={numberPerPage}
                    total={count}
                    hideOnSinglePage={true}
                    showSizeChanger
                    onChange={handleCurrentPage}
                    className="pagination-data"
                    locale={locale}
                    itemRender={pagination.textItemRender}
                />
            </div>
            <Modal
                visible={taskModal}
                onOk={handleCancel}
                onCancel={handleCancel}
                footer={null}
                className="customModal"
                title={modalType === "details" ?
                    "My Task Details" :
                    <RescheduleHeader setModalType={setModalType} type ="reassign"/>
                }
            >
                {modalType === "details" ?
                    <GenericTaskDetailsModal
                        selectedTask={selectedTask}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        // getMyLeadActivityService={getMyLeadActivityService}
                        setModalType={setModalType}
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}
                    />
                    :
                    <Reschedule
                        selecteddetails={selectedTask}
                        handleCancel={handleCancel}
                        handleSave={handleSave}
                        rescheduleDetails={rescheduleDetails}
                        setRescheduleDetails={setRescheduleDetails}
                        type={"task"}
                    />

                }
            </Modal>
        </div>
    )
}

export default GenericTaskDetails

// ${item.leadStatus.includes("Allocated") ? "Potential" : item.leadStatus.concat("-active")}