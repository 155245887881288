import moment from 'moment';
import React from 'react';
import { Cursor } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";
import '../Leads.css'



const LeadDetails = ({ selectedUser, type, errorMsg, setMainScreen, closeModal }) => {
    console.log("selected user", selectedUser, "type", type)
    

    return (
        <>
            <div className="leadDetails col-7">
            
                {type === "mytasks" || type === "myappointments" || type === "mycalls" ? (
                    // <Link
                    //     to={{
                    //         pathname: "/landing/salesexecutive/genericactivities",
                    //         state: { selectedUser, type }
                    //     }}
                    // >
                    //     <h4>{selectedUser?.leadName}</h4>
                    // </Link>
                    <span
                        // onClick={() => {
                        //     setMainScreen("genericScreen")
                        //     closeModal()
                        // }}
                        // className='linkTypeElement'
                        // style={{cursor:'pointer',textDecoration:'underline', textDecorationColor: "blue"}}
                        style={{fontWeight:500,fontSize:19.5}}
                    >
                        {selectedUser?.leadName}
                        <FiEdit  className="editIcon"  onClick={() => {
                      setMainScreen("genericScreen")
                            closeModal()
                            
                        }}
                        style={{color:"#F8A504", borderRadius:"8px",marginLeft:"5px",fontSize:"22px",cursor:"pointer",
                            padding:"3px"
                        }}
                      
                        />
                    </span>
                  
                ) : type === "generic" ? (
                   <h4 >{selectedUser?.leadName}</h4>

                ) : (
                    <h4 style={{fontWeight:500,fontSize:19.5}}>
                        {selectedUser?.name || selectedUser?.leadName || selectedUser?.customerName}{" "}
                        {selectedUser?.lastName}
                    </h4>
                )}
                <div>
                    <span>{selectedUser?.email || selectedUser?.leadEmail || selectedUser?.customerEmail},</span>
                    <span>
                        {selectedUser?.mobile ||
                            selectedUser?.leadMobile ||
                            selectedUser?.customerMobile ||
                            selectedUser?.leadMobileNumber}
                    </span>
                    <p>
                        <span>
                            {selectedUser?.leadcreatedDateTime &&
                                moment(selectedUser?.leadcreatedDateTime).utc().format("D MMM YYYY")}
                        </span>
                        <span>
                            {selectedUser?.leadcreatedDateTime &&
                                moment(selectedUser?.leadcreatedDateTime).utc().format(", h:mm a")}
                        </span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default LeadDetails