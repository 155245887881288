import { createSlice } from "@reduxjs/toolkit";




const LoginSlice=createSlice({
    name:"login",
    initialState:{
        loading:false,
        logindata:[],
        message:null
    },
    reducers:{
        loginLoader:(state,action)=>{
            // console.log("inside the login loader",state,action);
            state.loading=true;
        },
        loginSuccess:(state,action)=>{
            // console.log('inside the loginsuccess',state,action);
            state.loading=false;
            state.logindata=action.payload;
        },
        loginFailure:(state,action)=>{
            // console.log('inside the loginfailure',state,action);
            state.loading=false;    
            state.message=action.payload;
        }
    }
})


// console.log("loginslice ::",LoginSlice);
export const {loginLoader,loginSuccess,loginFailure} =LoginSlice.actions;
export default LoginSlice.reducer;