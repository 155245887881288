import { DatePicker, Upload,Modal } from 'antd'
import React,{useState} from 'react'
import { CloudUploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import "./AddTask.css";
import { disabledDate } from '../../../../../Utilities/helpers';
// import { before } from 'node:test';

const AddTask = (
    {
        leadStatus,
        handleLeadActivities
    }
) => {
    const [previewVisible,setPreviewVisible]=useState(false);
    const [previewImage,setPreviewImage]=useState("");
    const [previewTitle,setPreviewTitle]=useState("")

   console.log("lead status----",moment(leadStatus?.taskStartDate).format("YYYY-MM-DD"),moment(leadStatus?.taskStartDate).format("YYYY-MM-DD"));

    const disabledCustomized = (current) => {
        console.log("current",current);
        return current && current.isBefore(moment(leadStatus?.taskStartDate).add(1, 'day'));
    }

    
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
    
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    
    // Helper function to convert file to base64
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const beforeUpload = (file) => {
        return false; 
    };

    return (
        <>
            <div className='row'>
                <div className='col-6'>
                    <label> Start Date</label>
                    <DatePicker
                        onChange={handleLeadActivities("taskStartDate")}
                        format={"YYYY-MM-DD"}
                        value={leadStatus?.taskStartDate ? moment(moment(leadStatus?.taskStartDate).format("YYYY-MM-DD")) : ""}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className='col-6'>
                    <label>Due Date</label>
                    <DatePicker
                        onChange={handleLeadActivities("taskEndDate")}
                        format={"YYYY-MM-DD"}
                        value={leadStatus?.taskEndDate && moment(moment(leadStatus?.taskEndDate).format("YYYY-MM-DD"))}
                        disabled={leadStatus?.taskStartDate ? false : true}
                        disabledDate={disabledCustomized}
                    />
                </div>
            </div>
            <div className='row'>
                <label>Upload Images</label>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    onPreview={handlePreview}
                    beforeUpload={beforeUpload}
                                        // showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }} 
                >
                    <div className='upload-wrapper'>
                        <CloudUploadOutlined />
                        click to upload
                    </div>
                </Upload>
                <Modal
                    visible={previewVisible}
                    // title={previewTitle}
                    footer={null}
                    onCancel={() => setPreviewVisible(false)}
                    
                >
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        </>
    )
}

export default AddTask;