import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getActivityStates, updateLeadState } from '../../../../../../Service/MyLeadsService';
import LeadDetails from '../../../MyLeads/LeadDetails/LeadDetails';
import "../../GenericMeetingDetails/GenericMeetingDetailsModal/GenericMeetingDetailsModal.css";


const GenericTaskDetailsModal = ({ 
    selectedTask, 
    handleCancel,
    setModalType,
    handleSave,
    selectedState,
    setSelectedState
 }) => {

    console.log("task", selectedTask);
    const [options, setOptions] = useState([]);
  
    const getTaskStates = async () => {
        try {
            const response = await getActivityStates({ activityTypeId: 3 });
            console.log('response', response);
            if (response.status === "success") {
                const options = response.data.map((value) => ({ label: value.activityStateName, value: value.activityStateId }));
                console.log(options);
                setOptions(options);
            }
            else {
                setOptions([]);
            }

        } catch (error) {
            console.log("error", error);
            setOptions([]);
        }
    }

    console.log("options", options);

  
    const handleSelect = (e) => {
        // console.log('value', e)
        setSelectedState(e);
    }

    useEffect(() => {
        getTaskStates();
        setSelectedState(selectedTask?.activityState);
    }, [selectedTask])

    // console.log("selected option", selectedState);

    return (
        <div className='row'>
            <div className='row' >
                <div className='leaddetails-wrapper'>
                <LeadDetails selectedUser={selectedTask} type="generic" />
                <div className='col-2'>
                    <button
                        className='btn whBtnPrimary'
                        onClick={() => setModalType("reasssign")}
                        // disabled={selectedTask?.activityState === "Rescheduled" ? true :false}
                    >
                        Reassign
                    </button>
                </div>
                <div className='col-3'>


                    <Select
                        options={options}
                        style={{ width: "100%" }}
                        placeholder="select status"
                        value={selectedState}
                        onChange={handleSelect}
                    />
                </div>
            </div>
            </div>
            <div className='row'>
                <label>Sales Executive Comment</label>
                <div class="fW500">{selectedTask?.remarks?.replace(/(<([^>]+)>|&nbsp;)/ig, '\n')}</div>
            </div>

            <div className='row'>
                <label>Title</label>
                <div class="fW500">{selectedTask?.activityName}</div>
            </div>

            <div className='row'>
                <label>Description</label>
                <div class="fW500">{selectedTask?.activityDescription?.replace(/(<([^>]+)>|&nbsp;)/ig, '\n')}</div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-6'>
                            <label>Start Date</label>
                            <div class="fW500">{moment(selectedTask?.activityStartDate).format("D MMM, YYYY")}</div>
                        </div>
                        <div className='col-6'>
                            <label>Due Date</label>
                            <div class="fW500">{moment(selectedTask?.activityDueDate).format("D MMM, YYYY")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modalFooter'>
                <button type="button" className="btn cancelBtn" onClick={() => handleCancel()}>cancel</button>
                <button type="button" className="btn saveBtn" onClick={() => handleSave("detailsSave")}>Save</button>
                <button type="button" className="btn saveBtn" onClick={() => handleSave("detailsSaveandClose")}>Save&close</button>

            </div>
        </div>
    )
}

export default GenericTaskDetailsModal

