import React from 'react'
import { useHistory,Link } from 'react-router-dom';
import { AiOutlinePoweroff } from 'react-icons/ai';

function Signout() {
    const history = useHistory()
    
    const landingBtn = () => {
        window.localStorage.setItem('isErrMsg',false);
        history.push({pathname:'/signin'})

    }
    return (
        <div className="off-icon-layout" onClick={landingBtn}>
            <AiOutlinePoweroff className="off-icon" />
            <Link to="/signin" >SignOut</Link>
        </div>
    )
}

export default Signout;